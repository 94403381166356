import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Slider } from "../../components/slider/Slider";
import slider1 from "../../assets/img/slider1.jpg";
import { buttonsFood } from "../../data/buttons_food";
import { useNavigate } from "react-router-dom";
import img7 from "../../assets/img/compressimg/77777-min.png";
import { MenuDropdown } from "../../components/menu/MenuDropdown";
import { useLanguage } from "../../context/LanguageProvider";

export const Carta = () => {
  const navigate = useNavigate();
  const { lang } = useLanguage();
  const languages = {
    es: {
      title: "Carta - Karnivoro Restaurant",
    },
    en: {
      title: "Menu - Karnivoro Restaurant",
    },
  };

  document.title = languages.es.title;

  // redirect to https://app.okeymenu.com/lSmSoq/es
  useEffect(() => {
    window.location.href = "https://app.okeymenu.com/lSmSoq/es";
  }, []);

  
  return (
    <div className="mb-4">
      <MenuDropdown />
      <Slider img={img7} />
      <h1 className="text-center cart-title">Carta</h1>
      <Row>
        {buttonsFood.map((button) =>
          button[lang].map((btn) => {
            return (
              <Col xs={6} md={2} key={btn.id} style={{ margin: "auto" }}>
                <div className="d-flex flex-column align-items-center mt-4">
                  {/* <img src={btn.icon} alt={btn.name} width="70" /> */}
                  <Button
                    variant="primary"
                    className="mt-2 text-uppercase text-light"
                    onClick={() => navigate(btn.url)}
                  >
                    <b>{btn.name}</b>
                  </Button>
                </div>
              </Col>
            );
          })
        )}
      </Row>
    </div>
  );
};
