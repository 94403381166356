import React, { useEffect } from 'react'
import { Accordions } from '../../components/accordion/Accordions'
import { combinadosData } from '../../data/carta/combinados.js'
import { MenuDropdown } from '../../components/menu/MenuDropdown';

export const Combinados = () => {
    const lang = localStorage.getItem('lang') || 'es';

    const data = {
        es: {
            title: 'Combinados - Karnivoro Restaurant',
            description: 'TODOS LOS COMBINADOS CON BEBIDA ENERGÉTICA o TÓNICA PREMIUM TENDRÁN UN INCREMENTO DE 1 EURO SOBRE EL PRECIO DE LA COPA',
        },
        en: {
            title: 'Drinks - Karnivoro Restaurant',
            description: 'ALL COMBINATIONS WITH ENERGY DRINK OR PREMIUM TONIC WILL HAVE AN INCREASE OF 1 EURO ON THE PRICE OF THE GLASS',
        }
    }

    document.title = data.es.title;
    useEffect(() => {
        window.location.href = "https://app.okeymenu.com/lSmSoq/es";
      }, []);
  return (
    <div>
        <div className='text-center'>  
            <MenuDropdown />   
        </div>
        <div className='mt-4'>
            <h6 className='text-center text-uppercase'><b>{data[lang].description}</b></h6>
        </div>
        <div className='mt-4'> 
            <Accordions data={combinadosData} lang={lang} />
        </div>
    </div>
  )
}
