import React, { useEffect } from 'react'
import { Accordions } from '../../components/accordion/Accordions'
import { principalData } from '../../data/carta/principal.js'
import { MenuDropdown } from '../../components/menu/MenuDropdown';

export const Principal = () => {

    const lang = localStorage.getItem('lang') || 'es';
    const data = {
        es: {
            title: 'Carta Principal - Karnivoro Restaurant',
        },
        en: {
            title: 'Main Menu - Karnivoro Restaurant',
        }
    }

    document.title = data[lang].title;
    useEffect(() => {
        window.location.href = "https://app.okeymenu.com/lSmSoq/es";
      }, []);
  return (
    <div>
        <div className='text-center'>  
            <MenuDropdown />   
        </div>
        {/* <div className='mt-4'>
            <h1 className='text-center text-uppercase'><b>{data.es.title}</b></h1>
        </div> */}
        <div className='mt-4'> 
            <Accordions data={principalData} lang={lang} />
        </div>
    </div>
  )
}
