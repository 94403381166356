import chilli from '../../assets/icons/chilli.png';

// random id generator
const randomId = () => {
    return Math.floor(Math.random
    () * 100000000
    );
};

export const principalData = [{
    es: [{
        category: [{
            id: 0,
            name: 'Aperitivos Karnivoros',
            items: [{
                id: 1,
                name: 'Chapata de carrillada ibérica con provolone del terreno y emulsión de mayonesa',
                description: null, 
                price: 5,
                isUnit: true
            },
            {
                id: 2,
                name: 'Crujiente de morcilla de arroz con pimiento del piquillo y tomatitos cherry con cama agripicante',
                description: null, 
                price: 3,
                isUnit: true
            },
            {
                id: 3,
                name: 'Pinchito de cordero brasa estilo moruno',
                description: null,
                price: 6,
                isUnit: true,
                icon: chilli
            },
            {
                id: 4,
                name: 'Crepes de ternera angus al curry con salsa de yogur',
                description: null,
                price: 3.5,
                
            },
            {
                id: randomId(),
                name: 'Chorizo Criollo',
                description: 'Laminado de chorizo criollo con salsa chimichurri',
                price: 4
            },
            {
                id: randomId(),
                name: 'Patatas bravas',
                description: null,
                price: 7
            },
            {
                id: 30,
                name: 'Empanadilla Karnivora',
                description: 'Empanadilla ternera rubia Gallega al estilo argentino',
                price: 4.50,
                icon: chilli
            }
            ]
        },
        {   
            id: 1,
            name: 'Entradas Karnivoras',
            items: [
            {
                id: randomId(),
                name: 'Magret de Pato',
                description: 'con reducción de Pedro Ximenez y mermela de fresa', 
                price: 14
            },
            {
                id: randomId(),
                name: 'Rusa Karnivora',
                description: 'Nuestro clásico', 
                price: 8
            },
            // {
            //     id: randomId(),
            //     name: 'Rusa Ibérica',
            //     description: 'Con lascas de jamón',
            //     price: 10
            // },    
            {
                id: 5,
                name: 'Gulas karnivoras con chistorra de buey y huevo ecológico con perlas trufadas',
                description: null,
                price: 16,
                
            },
            // {
            //     id: 6,
            //     name: 'Chistorra del Pais Vasco',
            //     description: 'de buey nacional',
            //     price: 12,
                
            // },
            {
                id: 7,
                name: 'Jamón ibérico de bellota 100%',
                description: null,
                price: 24,
                
            },
            {
                id: 8,
                name: 'Salchichón ibérico de bellota 100%',
                description: null,
                price: 16,
                
            },
            {
                id: 9,
                name: 'Lomo ibérico de bellota 100%',
                description: null,
                price: 24,
                
            },
            {
                id: 10,
                name: 'Chorizo ibérico de bellota 100%',
                description: null,
                price: 16,
                
            },
            {
                id: 11,
                name: 'Surtido de ibéricos bellota XXL',
                description: 'Lomo de bellota, salchichón de bellota, chorizo de bellota y jamón de bellota y queso añejo',
                price: 24,
                
            },
            {
                id: 12,
                name: 'Tabla de quesos nacional / internacional',
                description: 'Surtido de 7 quesos variados de distintas intensidades, provenientes de distintas ciudades de España y del extranjero',
                price: 20,
            },
            {
                id: 13,
                name: 'Cremosa torta de rufino',
                description: null,
                price: 10,
            },
            {
                id: 14,
                name: 'Cecina de buey 100 días de maduración',
                description: null,
                price: 18,
            },
            {
                id: 15,
                name: 'Cecina de vaca',
                description: null,
                price: 14,
            },
            {
                name: 'Medallones de fua de pato',
                description: 'Con reduccion de Pedro Ximenez sobre cama de mermelada de arándanos',
                price: 10,
            },
            {
                id: 16,
                name: 'Sobrasada ibérica a la brasa con lascas queso parmigiano',
                description: null,
                price: 8,
            },
            {
                id: 17,
                name: 'Morcilla de burgos en tripa natural',
                description: 'A la brasa con toque de pimenton de la vera sobre cama de mermelada de tomate del terreno',
                price: 8,
            },
            {
                id: 18,
                name: 'Morcilla Karnivora',
                description: 'Foe de Pato, Huevo codorniz y Toque de Ángel',
                price: 10,
            },
            {
                id: 19,
                name: 'Chistorra del País Vasco D,O,',
                description: 'Ganadora 1er Premio Nacional mejor Chistorra',
                price: 12,
            },
            {
                id: 20,
                name: 'Carpaccio picanha con lascas queso parmigiano',
                description: null,
                price: 14,
            },
            {
                id: 21,
                name: 'Patatas bravas',
                description: 'Patatas seleccionadas en 2 cocciones con nuestra salsa picantona',
                price: 6,
            },
            {
                id: 22,
                name: 'Pastel de berenjena',
                description: 'Con picado de ternera rubia galleta al horno con mozarella y toque de miel al pimenton ahumado',
                price: 8.5,
            },
            {
                id: 23,
                name: 'Rusa Karnivora',
                description: 'Nuestra rusa con virutas jamón ibérico bellota',
                price: 10
            },
            {
                id: 24,
                name: 'Anchoas de Santoña',
                description: '8 unidades seleccionadas de Santoña con cama de triturado del terreno',
                isUnit: true,
                numberUnit: 8,
                price: 16
            },
            {
                id: 25,
                name: 'Boquerones en vinagre',
                description: null,
                price: 8
            },
            {
                id: 26,
                name: 'Pil-Pil Karnivoro',
                description: 'Espectacular pil pil con chistorra navarra y 2 huevos con sal negra a elegir entre:',
                price: null,
                items: [{
                    name: 'Langostinos salvajes',
                    price: 14
                },
                {
                    name: 'Gambones',
                    price: 18
                },
                {
                    name: 'Carabirenos',
                    price: 20
                }]
            },
            {
                id: 27,
                name: 'Sartén de provolone con tomate del terreno y chistorra',
                description: null,
                price: 10
            }
            ]
        },
        {
            id: 2,
            name: 'Croquetas Karnivoras',
            items: [{
                id: 28,
                name: 'Las del Chef',
                description: null,
                price: 12,
                isUnit: true,
                numberUnit: 8,
                items: [{
                    name: 'Croquetas de jamón de bellota',
                    price: null
                },
                {
                    name: 'Croquetas de cerdo picantón',
                    price: null
                },
                {
                    name: 'Croquetas de buey',
                    price: null
                },
                {
                    name: 'Croquetas de trufa y setas',
                    price: null
                },
                {
                    name: 'Croquetas de chipirón',
                    price: null
                }]
            },
            {
                id: 29,
                name: 'Las Karnivoras',
                description: null,
                price: 10,
                isUnit: true,
                numberUnit: 8,
                items: [{
                    name: 'Croquetas de la abuela (las auténticas)',
                    price: null
                },
                {
                    name: 'Croquetas de txangurro',
                    price: null
                },
                {
                    name: 'Croquetas de chistorra',
                    price: null
                },
                {
                    name: 'Croquetas de cabrales con pasas',
                    price: null
                },
                {
                    name: 'Croquetas de queso de oveja',
                    price: null
                },
                {
                    name: 'Croquetas de cecina',
                    price: null
                },
                {
                    name: 'Croquetas de rabo de toro',
                    price: null
                },
                {
                    name: 'Croquetas de almejas marineras',
                    price: null
                },
                {
                    name: 'Croquetas de pollo asado',
                    price: null
                }]
            },
        ],
        },
        {
            id: 3,
            name: 'Para compartir los Karnivoros',
            items: [
            {
                id: randomId(),
                name: 'Cachopo',
                description: 'Finas lonchas de ternera madurada con queso fundido y jamon serrano',
                price: 21,
            },
            // {
            //     id: 31,
            //     name: 'Cachopín de vaca madurada',
            //     description: '800gr',
            //     price: 21,
            // },
            {
                id: 32,
                name: 'Cachopín de vaca madurada al cabrales con pimientos de piqilllo',
                description: 'Finas lonchas de ternera madurada con queso cabrales pimientos del piquillo y jamón serrano',
                price: 21,
            },
            {
                id: 33,
                name: 'Carrillada ibérica Joselito',
                description: 'Con cocción a baja temperatura, con 7 tipos de verduras emulsionando una salsa espectacular',
                price: 18,
            },
            {
                id: 34,
                name: 'Rabo de toro',
                description: 'Clasico de la casa',
                price: 18,
            },
            {
                id: 35,
                name: 'Pata pulpo Karnivora',
                description: 'Sobre cama de Puré y Jugo de Miel con Pimentón Ahumado',
                price: 24,
            },
            {
                id: 36,
                name: 'Lingotes crujientes camembert',
                description: 'Con arandanos frescos de temporada',
                price: 10,
            },
            {
                id: 37,
                name: 'Revuelto de setas',
                description: 'Revuelto de setas variadas con langostinos salvajes',
                price: 12,
            },
            {
                id: 38,
                name: 'Revuelto de salmón',
                description: 'Revuelto de salmón ahumado, mozzarella, tomate seco y verduras',
                price: 12,
            },
            {
                id: 39,
                name: 'Revuelto Karnivoro',
                description: 'Revuelto de morcilla de arroz salteado con pimientos del piquillo y cebolleta',
                price: 12,
            },
            {
                id: 40,
                name: 'Revuelto de espárragos',
                description: 'Revuelto de espárragos, champiñones, jamón ibérico bellota y langostinos salvajes',
                price: 12,
            },
            {
                id: 41,
                name: 'Crepes de txangurro',
                description: null,
                price: 12,
            },
            {
                id: 42,
                name: 'Crepes de chipirion en su tinta',
                description: null,
                price: 12,
            },
            {
                id: 43,
                name: 'Crepes de puerros y gambas',
                description: null,
                price: 12,
            }
        ]
        },
        { 
        id: 4,
        name: 'Ensaladas Karnivoras',
        items: [{
            id: 44,
            name: 'La Karnivora',
            description: 'Mezclún con romana, pops de pollo, rulo de queso de cabra braseado, pimiento endulzado, frutos secos y nuestra salsa curry suave',
            price: 12,
        },
        {
            id: 45,
            name: 'La burrata',
            description: 'Lámina de aguacate, tomate del terreno, perlas de trufa negra, burrata fresca y nuestra salsa albahaca casera',
            price: 12,
        },
        {
            id: randomId(),
            name: 'La Cremosa',
            description: 'Cremosa salsa de aguacates con hojas baby y mezclum langostinos salvajes fruta de temporada y dados de queso',
            price: 12,
        },
        {
            id: 46,
            name: 'La ahumada',
            description: 'Mezclún con romana, canónigos, rúcula, naranja, salmón ahumado y piñones',
            price: 12,
        },
        {
            id: 47,
            name: 'La más verde',
            description: 'Mezclún con romana, espárragos cojonudos, aguacate, huevo cocido, maíz, atún, cebolla morá y tomate del terreno',
            price: 12,
        },
        {
            id: 48,
            name: 'Ensalada de pimientos asados Karnivoros',
            description: 'Timbal de pimientos asados al carbón con cebolla fresa, atún y huevo frito',
            price: 12,
        },
        {
            id: 49,
            name: 'La cesar',
            description: 'Mezclún con romana, rulo queso de cabra braseado, asado de pollo, picatostes, corte de olivas negras con lascas de graná padano y nuestra salsa cesar',
            price: 12,
        },
        ]
        },
        {
            id: 5,
            name: 'Tartar ',
            items: [{
                id: 50,
                name: 'Tartar de salchicón de Málaga',
                description: null,
                price: 10,
            },
            {
                id: 51,
                name: 'Tartar de pulpo',
                description: null,
                price: 12,
            },
            {
                id: 52,
                name: 'Tartar de atún',
                description: null,
                price: 14,
            },
            {
                id: 53,
                name: 'Tartar de salmón fresco',
                description: null,
                price: 16,
            },
            {
                id: 54,
                name: 'Tartar de salmón ahumado',
                description: null,
                price: 16,
            },
            {
                id: 55,
                name: 'Tartar de bacalao fresco',
                description: null,
                price: 16,
            },
            {
                id: 56,
                name: 'Tartar de bacalao ahumado',
                description: null,
                price: 16,
            },
            {
                id: 57,
                name: 'Tartar de rubia gallega',
                description: 'El autentico',
                price: 21,
            }],
        },
        // {
        //     id: 6,
        //     name: 'No todo es carne',
        //     items: [
        //     // {
        //     //     id: 58,
        //     //     name: 'Langostinos a la plancha',
        //     //     description: null,
        //     //     price: 24,
        //     // },
        //     ],
        // },
        {
            id: 7,
            name: 'Menú infantil',
            items: [{
                id: 71,
                name: 'Delicias de pollo con patatas y 3 salsas acompañado de patatas paja',
                description: null,
                price: 10,
            },
            {
                id: 72,
                name: 'Macarrones Karnivoros',
                description: 'Boloñesa de ternera gallega y queso rallado al gusto',
                price: 10,
            },
            {
                id: 73,
                name: 'Hamburguesa de rubia gallega con queso fundido y salsa burger acompañado de patatas paja',
                description: '200gr',
                price: 14,
            }]
        },
        {
            id: 11,
            name: 'El Pescaito de Karnivoro',
            description: 'Consultar por pescados del dia de la bahia',
            items: [{
                id: 111,
                name: 'Bacalao frito con alioli',
                description: null,
                price: 18
            },
            {
                id: 112,
                name: 'Bacalao al horno',
                description: 'Con pisto manchego casero',
                price: 18
            },
            {
                id: 113,
                name: 'Calamares nacionales fritos en rodajas',
                description: null,
                price: 18
            },
            {
                id: 114,
                name: 'Jibia frita',
                description: null,
                price: 10
            },
            {
                id: 115,
                name: 'Boquerones Vitorianos',
                description: null,
                price: 10
            },
            {
                id: 59,
                name: 'Gambas cocidas en Huelva',
                description: null,
                price: 18,
            },
            {
                id: 60,
                name: 'Gambas a la plancha de Huelva',
                description: null,
                price: 18,
            },
            {
                id: 61,
                name: 'Zaburiñas Karnivoras con salsa ranchera',
                description: null,
                price: 15,
                isUnit: true,
                number: 6
            },
            {
                id: 62,
                name: 'Ostras',
                description: null,
                price: 4,
                isUnit: true,
            },
            {
                id: 63,
                name: 'Mejillones al vapor',
                description: null,
                price: 9,
            },
            {
                id: 64,
                name: 'Almejas carril salteadas',
                description: null,
                price: 16,
            },
            {
                id: 65,
                name: 'Rabas de choco con alioli',
                description: null,
                price: 9,
            },
            {
                id: 66,
                name: 'Bacalao fresco al horno con pisto casero',
                description: 'Ración 250gr',
                price: 18,
            },
            {
                id: 67,
                name: 'Bacalao fresco frito',
                description: null,
                price: 18,
            },
            {
                id: 68,
                name: 'Lubina',
                description: '800gr',
                price: 21,
            },
            {
                id: 69,
                name: 'Dorada',
                description: '800gr',
                price: 21,
            },
            {
                id: 70,
                name: 'Rosada a la plancha / frita',
                description: null,
                price: 12,
            }    
            ]
        },
        {
            id: 8,
            name: 'Carnes Karnivoras',
            items: [],
        },
        {
            id: 74,
            name: 'Cerdo Karnivoro',
            items: [{
                id: 75,
                name: 'Solomillo de bellota',
                description: null,
                price: 18,
            },
            {
                id: 76,
                name: 'Presa de bellota',
                description: null,
                price: 21,
            },
            {
                id: 77,
                name: 'Abanico de bellota',
                description: null,
                price: 18,
            },
            {
                id: 78,
                name: 'Lagarto de bellota',
                description: null,
                price: 18,
            },
            {
                id: 79,
                name: 'Pluma de bellota',
                description: null,
                price: 18,
            }],
        },
        {
            id: 80,
            name: 'Ternera Karnivora',
            items: [{
                id: 81,
                name: 'Medallón de vaca vieja',
                description: null,
                price: 22,
            },
            {
                id: 82,
                name: 'Medallón Rubia gallega',
                description: null,
                price: 26,
            },
            {
                id: 83,
                name: 'Medallón punta solomillo',
                description: null,
                price: 18,
            },
            {
                id: 84,
                name: 'Chateaubriand',
                description: null,
                price: 36,
            },
            {
                id: 85,
                name: 'Entrañas',
                description: null,
                price: 21,
            }]
        },
        {
            id: 86,
            name: 'Entrecot',
            description: 'Cortes generosos todos con un minimo de 30 dias de maduracion',
            items: [{
                id: 87,
                name: 'Vaca vieja',
                description: null,
                price: 28,
            },
            {
                id: 88,
                name: 'Rubia gallega',
                description: null,
                price: 32,
            },
            {
                id: 89,
                name: 'Ternera rosada',
                description: null,
                price: 26,
            },
            {
                id: 90,
                name: 'Ternera blanca',
                description: null,
                price: 25,
            },
            {
                id: 91,
                name: 'Angus',
                description: null,
                price: 28,
            },
            {
                id: 92,
                name: 'Rib Eye',
                description: 'aprox, 1kg',
                price: 55,
            },
            {
                id: 93,
                name: 'Buey',
                description: 'min, 1kg',
                price: 120,
                isUnit: true,
                unit: 'kg',
            }]
        },
        {
            id: 94,
            name: 'Chuletón',
            description: 'Cortes con un minimo de 1,3Kg seleccionando los mejores canales con una filtración que te fascinará',
            items: [{
                id: 95,
                name: 'Ternera rosada',
                description: null,
                price: 50,
            },
            {
                id: 96,
                name: 'Ternera blanca',
                description: null,
                price: 55,
            },
            {
                id: 97,
                name: 'T-Bone',
                description: null,
                price: 55,
                isUnit: true,
                unit: 'kg',

            },
            {
                id: 98,
                name: 'Rubia gallega',
                description: null,
                price: 79,
            },
            {
                id: 99,
                name: 'Tomahawk',
                description: null,
                price: 90,
                isUnit: true,
                unit: 'kg',

            },
            {
                id: 100,
                name: 'Holstein frisona',
                description: null,
                price: 74,
                isUnit: true,
                unit: 'kg',

            },
            {
                id: 101,
                name: 'Simmental',
                description: null,
                price: 78,
                isUnit: true,
                unit: 'kg',

            },
            {
                id: 102,
                name: 'Chuleta vasca dehesa bellota',
                description: null,
                price: 90,
                isUnit: true,
                unit: 'kg',
            },
            {
                id: 103,
                name: 'Buey',
                description: null,
                price: 120,
                isUnit: true,
                unit: 'kg',

            }]
        },
        {
            id: 9,
            name: 'Guarniciones',
            items: [{
                id: 104,
                name: '',
                price: 3.5,
                items: [{
                    id: 105,
                    name: 'Pure',
                    description: null,
                },
                {
                    id: 106,
                    name: 'Arroz de coco con pasas',
                    description: null,
                },
                {
                    id: 107,
                    name: 'Patata asada',
                    description: null,
                },
                {
                    id: 108,
                    name: 'Patata asada con alioli',
                    description: null,
                },
                {
                    id: 109,
                    name: 'Patatas fritas',
                    description: null,
                },
                {
                    id: 110,
                    name: 'Pimentón padrón',
                    description: null,
                }]
            },
            {
                id: 111,
                name: 'Verduras salteadas con champiñones y setas del terreno',
                description: 'En plato',
                price: 5,
            }]
        },
        {
            id: 10,
            name: 'Salsas',
            items: [{
                id: 112,
                name: 'Pimienta verde',
                description: null,
                price: 3,
            },
            {
                id: 113,
                name: 'Roquefort',
                description: null,
                price: 3,
            },
            {
                id: 114,
                name: 'Champiñones',
                description: null,
                price: 3,
            },
            {
                id: 115,
                name: 'Bearnesa',
                description: null,
                price: 3,
            },
            {
                id: 116,
                name: 'Chimichurri',
                description: null,
                price: 2,
            },
            {
                id: 117,
                name: 'Mojo picón',
                description: null,
                price: 2,
            },
            {
                id: 118,
                name: 'Suave de curry',
                description: null,
                price: 1,
            },
            {
                id: 119,
                name: 'Cesar',
                description: null,
                price: 1,
            }]
        }],
    }],
    en: [{
        category: [{
            id: 0,
            name: 'Karnivore Snacks',
            items: [{
                id: 1,
                name: 'Iberian cheek ciabatta with local provolone and mayonnaise emulsion',
                description: null,
                price: 5,
                isUnit: true
            },
            {
                id: 2,
                name: 'Crispy rice blood sausage with piquillo pepper and cherry tomatoes on a sour-spicy bed',
                description: null,
                price: 3,
                isUnit: true
            },
            {
                id: 3,
                name: 'Moorish style grilled lamb skewer',
                description: null,
                price: 6,
                isUnit: true,
                icon: chilli
            },
            {
                id: 4,
                name: 'Curry Angus Beef Crepes with Yogurt Sauce',
                description: null,
                price: 3.5,
            },
            {
                id: randomId(),
                name: 'Criollo Chorizo',
                description: 'Laminated Creole chorizo with chimichurri sauce',
                price: 4
            },
            {
                id: randomId(),
                name: 'Bravas potatoes',
                description: null,
                price: 7
            },
            {
                id: 30,
                name: 'Karnivora Dumpling',
                description: 'Argentinian style Galician blonde beef empanadilla',
                price: 4.50,
                icon: chilli
            }
            ]
        },
        {
            id: 1,
            name: 'Karnivora Entries',
            items: [
            {
                id: randomId(),
                name: 'Duck Magret',
                description: 'with Pedro Ximenez reduction and strawberry jam',
                price: 14
            },
            {
                id: randomId(),
                name: 'Russian Karnivora',
                description: 'Our classic',
                price: 8
            },
            // {
            // id: randomId(),
            // name: 'Russian Iberian',
            // description: 'With ham slices',
            // price: 10
            // },
            {
                id: 5,
                name: 'Carnivora eels with beef sausage and organic egg with truffled pearls',
                description: null,
                price: 16,
               
            },
            // {
            // id: 6,
            // name: 'Chistorra from the Basque Country',
            // description: 'national beef',
            // price: 12,
               
            // },
            {
                id:7,
                name: '100% acorn-fed Iberian ham',
                description: null,
                price: 24,
               
            },
            {
                id:8,
                name: '100% acorn-fed Iberian salchichón',
                description: null,
                price: 16,
               
            },
            {
                id: 9,
                name: '100% acorn-fed Iberian loin',
                description: null,
                price: 24,
               
            },
            {
                id: 10,
                name: '100% acorn-fed Iberian chorizo',
                description: null,
                price: 16,
               
            },
            {
                id: 11,
                name: 'Assortment of XXL Iberian acorn-fed',
                description: 'Acorn-fed loin, acorn-fed salchichón, acorn-fed chorizo and acorn-fed ham and aged cheese',
                price: 24,
               
            },
            {
                id: 12,
                name: 'National/International Cheese Board',
                description: 'Assortment of 7 varied cheeses of different intensities, from different cities in Spain and abroad',
                price: 20,
            },
            {
                id: 13,
                name: 'Creamy rufino cake',
                description: null,
                price: 10,
            },
            {
                id: 14,
                name: 'Beef jerky 100 days of maturation',
                description: null,
                price: 18,
            },
            {
                id: 15,
                name: 'Cow jerky',
                description: null,
                price: 14,
            },
            {
                name: 'Duck fua medallions',
                description: 'With Pedro Ximenez reduction on a bed of blueberry jam',
                price: 10,
            },
            {
                id: 16,
                name: 'Grilled Iberian sobrasada with Parmigiano cheese flakes',
                description: null,
                price: 8,
            },
            {
                id: 17,
                name: 'Black pudding from Burgos in natural casing',
                description: 'Grilled with a touch of paprika from La Vera on a bed of local tomato jam',
                price: 8,
            },
            {
                id: 18,
                name: 'Morcilla Karnivora',
                description: 'Duck Foe, Quail Egg and Angels Touch',
                price: 10,
            },
            {
                id: 19,
                name: 'Chistorra from the Basque Country D,O,',
                description: 'Winner 1st National Prize for best Chistorra',
                price: 12,
            },
            {
                id: 20,
                name: 'Carpaccio picanha with parmigiano cheese flakes',
                description: null,
                price: 14,
            },
            {
                id: 21,
                name: 'Bravas potatoes',
                description: 'Selected potatoes in 2 cookings with our spicy sauce',
                price: 6,
            },
            {
                id: 22,
                name: 'Eggplant cake',
                description: 'With minced blonde beef baked biscuit with mozarella and a touch of honey with smoked paprika',
                price: 8.5,
            },
            {
                id: 23,
                name: 'Russian Karnivora',
                description: 'Our Russian with shavings of acorn-fed Iberian ham',
                price: 10
            },
            {
                id: 24,
                name: 'Santoña anchovies',
                description: '8 selected units from Santoña with ground crushing bed',
                isUnit: true,
                numberUnit: 8,
                price: 16
            },
            {
                id: 25,
                name: 'Anchovies in vinegar',
                description: null,
                price: 8
            },
            {
                id: 26,
                name: 'Pil-Pil Karnivoro',
                description: 'Spectacular pil pil with Navarrese chistorra and 2 eggs with black salt to choose between:',
                price: null,
                items: [{
                    name: 'Wild prawns',
                    price: 14
                },
                {
                    name: 'Prawns',
                    price: 18
                },
                {
                    name: 'Carabirenos',
                    price: 20
                }]
            },
            {
                id: 27,
                name: 'Provolone skillet with local tomato and chistorra',
                description: null,
                price: 10
            }
            ]
        },
        {
            id: 2,
            name: 'Karnivorous Croquettes',
            items: [{
                id: 28,
                name: 'The Chefs',
                description: null,
                price: 12,
                isUnit: true,
                numberUnit: 8,
                items: [{
                    name: 'Acorn-fed ham croquettes',
                    price: null
                },
                {
                    name: 'Spicy pork croquettes',
                    price: null
                },
                {
                    name: 'Ox croquettes',
                    price: null
                },
                {
                    name: 'Truffle and mushroom croquettes',
                    price: null
                },
                {
                    name: 'Squid croquettes',
                    price: null
                }]
            },
            {
                id: 29,
                name: 'The Karnivora',
                description: null,
                price: 10,
                isUnit: true,
                numberUnit: 8,
                items: [{
                    name: 'Grandma´s croquettes (the authentic ones)',
                    price: null
                },
                {
                    name: 'Txangurro croquettes',
                    price: null
                },
                {
                    name: 'Chistorra croquettes',
                    price: null
                },
                {
                    name: 'Cabrales croquettes with raisins',
                    price: null
                },
                {
                    name: 'Sheep cheese croquettes',
                    price: null
                },
                {
                    name: 'Cecina croquettes',
                    price: null
                },
                {
                    name: 'Oxtail croquettes',
                    price: null
                },
                {
                    name: 'Marine clam croquettes',
                    price: null
                },
                {
                    name: 'Croquroast chicken stews',
                    price: null
                }]
            },
        ],
        },
        {
            id: 3,
            name: 'To share the Karnivores',
            items: [
            {
                id: randomId(),
                name: 'Cachopo',
                description: 'Thin slices of matured beef with melted cheese and serrano ham',
                price: 21,
            },
            // {
            // id: 31,
            // name: 'Matured cow cachopín',
            // description: '800gr',
            // price: 21,
            // },
            {
                id: 32,
                name: 'Cachopín of cow matured in Cabrales with piqilllo peppers',
                description: 'Thin slices of matured beef with Cabrales cheese, piquillo peppers and serrano ham',
                price: 21,
            },
            {
                id: 33,
                name: 'Joselito Iberian cheek',
                description: 'Cooked at low temperature, with 7 types of vegetables emulsifying a spectacular sauce',
                price: 18,
            },
            {
                id: 34,
                name: 'Bull´s tail',
                description: 'House classic',
                price: 18,
            },
            {
                id: 35,
                name: 'Pata octopus Karnivora',
                description: 'On a bed of Puree and Honey Juice with Smoked Paprika',
                price: 24,
            },
            {
                id: 36,
                name: 'Camembert crispy ingots',
                description: 'With fresh seasonal blueberries',
                price: 10,
            },
            {
                id: 37,
                name: 'Scrambled mushrooms',
                description: 'Varied mushroom scramble with wild prawns',
                price: 12,
            },
            {
                id: 38,
                name: 'Salmon scramble',
                description: 'Scrambled smoked salmon, mozzarella, dried tomato and vegetables',
                price: 12,
            },
            {
                id: 39,
                name: 'Scrambled Karnivore',
                description: 'Scrambled rice blood sausage sautéed with piquillo peppers and chives',
                price: 12,
            },
            {
                id: 40,
                name: 'Asparagus scramble',
                description: 'Scrambled with asparagus, mushrooms, acorn-fed Iberian ham and wild prawns',
                price: 12,
            },
            {
                id: 41,
                name: 'Txangurro crepes',
                description: null,
                price: 12,
            },
            {
                id: 42,
                name: 'Chipirion crepes in its ink',
                description: null,
                price: 12,
            },
            {
                id: 43,
                name: 'Leek and shrimp crepes',
                description: null,
                price: 12,
            }
        ]
        },
        {
        id: 4,
        name: 'Karnivorous Salads',
        items: [{
            id: 44,
            name: 'The Karnivora',
            description: 'Tossed with romaine, chicken pops, braised goat cheese roll, sweetened pepper, nuts and our mild curry sauce',
            price: 12,
        },
        {
            id: 45,
            name: 'The burrata',
            description: 'Slice of avocado, local tomato, black truffle pearls, fresh burrata and our homemade basil sauce',
            price: 12,
        },
        {
            id: randomId(),
            name: 'The Creamy',
            description: 'Creamy avocado sauce with baby leaves and mesclun, wild prawns, seasonal fruit and cheese cubes',
            price: 12,
        },
        {
            id: 46,
            name: 'The smoked one',
            description: 'Mixed with romaine, lamb´s lettuce, arugula, orange, smoked salmon and pine nuts',
            price: 12,
        },
        {
            id: 47,
            name: 'The greenest',
            description: 'Mixed with romaine, great asparagus, avocado, boiled egg, corn, tuna, black onion and local tomato',
            price: 12,
        },
        {
            id: 48,
            name: 'Karnivoros Roasted Pepper Salad',
            description: 'Timbal of charcoal-roasted peppers with strawberry onion, tuna and fried egg',
            price: 12,
        },
        {
            id: 49,
            name: 'The Caesar',
            description: 'Mix with Romana, braised goat cheese roll, roast chicken, croutons, cut black olives with Graná Padano flakes and our Caesar sauce',
            price: 12,
        },
        ]
        },
        {
            id: 5,
            name: 'Tartar',
            items: [{
                id: 50,
                name: 'Malaga salchicón tartar',
                description: null,
                price: 10,
            },
            {
                id:51,
                name: 'Octopus tartare',
                description: null,
                price: 12,
            },
            {
                id: 52,
                name: 'Tuna tartare',
                description: null,
                price: 14,
            },
            {
                id:53,
                name: 'Fresh salmon tartare',
                description: null,
                price: 16,
            },
            {
                id:54,
                name: 'Smoked salmon tartare',
                description: null,
                price: 16,
            },
            {
                id:55,
                name: 'Fresh cod tartar',
                description: null,
                price: 16,
            },
            {
                id:56,
                name: 'Smoked cod tartar',
                description: null,
                price: 16,
            },
            {
                id:57,
                name: 'Galician blonde tartar',
                description: 'The authentic one',
                price: 21,
            }],
        },
        // {
        // id: 6,
        // name: 'Not everything is meat',
        // items: [
        // // {
        // // id: 58,
        // // name: 'Grilled prawns',
        // // description: null,
        // // price: 24,
        // // },
        // ],
        // },
        {
            id:7,
            name: 'Children´s menu',
            items: [{
                id:71,
                name: 'Chicken delights with potatoes and 3 sauces accompanied by straw potatoes',
                description: null,
                price: 10,
            },
            {
                id:72,
                name: 'Karnivore Macaroni',
                description: 'Galician beef bolognese and grated cheese to taste',
                price: 10,
            },
            {
                id:73,
                name: 'Galician blonde burger with melted cheese and burger sauce accompanied by straw potatoes',
                description: '200gr',
                price: 14,
            }]
        },
        {
            id: 11,
            name: 'El Pescaito de Karnivoro',
            description: 'Ask for fish of the day from the bay',
            items: [{
                id: 111,
                name: 'Fried cod with aioli',
                description: null,
                price: 18
            },
            {
                id: 112,
                name: 'Baked cod',
                description: 'With homemade Manchego ratatouille',
                price: 18
            },
            {
                id: 113,
                name: 'Sliced fried national squid',
                description: null,
                price: 18
            },
            {
                id: 114,
                name: 'Fried cuttlefish',
                description: null,
                price: 10
            },
            {
                id: 115,
                name: 'Vitorian anchovies',
                description: null,
                price: 10
            },
            {
                id:59,
                name: 'Prawns cooked in Huelva',
                description: null,
                price: 18,
            },
            {
                id: 60,
                name: 'Grilled shrimp from Huelva',
                description: null,
                price: 18,
            },
            {
                id: 61,
                name: 'Zaburiñas Karnivoras with ranchera sauce',
                description: null,
                price: 15,
                isUnit: true,
                number: 6
            },
            {
                id: 62,
                name: 'Oysters',
                description: null,
                price: 4,
                isUnit: true,
            },
            {
                id: 63,
                name: 'Steamed mussels',
                description: null,
                price: 9,
            },
            {
                id: 64,
                name: 'Sautéed Lane Clams',
                description: null,
                price: 16,
            },
            {
                id: 65,
                name: 'Choco rabas with aioli',
                description: null,
                price: 9,
            },
            {
                id: 66,
                name: 'Fresh baked cod with homemade ratatouille',
                description: 'Ration 250gr',
                price: 18,
            },
            {
                id: 67,
                name: 'Fresh fried cod',
                description: null,
                price: 18,
            },
            {
                id: 68,
                 name: 'Sea bass',
                 description: '800gr',
                 price: 21,
             },
             {
                 id: 69,
                 name: 'Golden',
                 description: '800gr',
                 price: 21,
             },
             {
                 id:70,
                 name: 'Grilled/fried rosada',
                 description: null,
                 price: 12,
             }
             ]
         },
         {
             id:8,
             name: 'Carnivorous Meats',
             items: [],
         },
         {
             id:74,
             name: 'Karnivore Pig',
             items: [{
                 id:75,
                 name: 'Acorn sirloin',
                 description: null,
                 price: 18,
             },
             {
                 id:76,
                 name: 'Acorn Prey',
                 description: null,
                 price: 21,
             },
             {
                 id:77,
                 name: 'Acorn fan',
                 description: null,
                 price: 18,
             },
             {
                 id:78,
                 name: 'Acorn Lizard',
                 description: null,
                 price: 18,
             },
             {
                 id:79,
                 name: 'Acorn feather',
                 description: null,
                 price: 18,
             }],
         },
         {
             id:80,
             name: 'Karnivora Calf',
             items: [{
                 id:81,
                 name: 'Old Cow Medallion',
                 description: null,
                 price: 22,
             },
             {
                 id:82,
                 name: 'Galician Blonde Medallion',
                 description: null,
                 price: 26,
             },
             {
                 id:83,
                 name: 'Sirloin tip medallion',
                 description: null,
                 price: 18,
             },
             {
                 id:84,
                 name: 'Chateaubriand',
                 description: null,
                 price: 36,
             },
             {
                 id:85,
                 name: 'Entryways',
                 description: null,
                 price: 21,
             }]
         },
         {
             id:86,
             name: 'Entrecote',
             description: 'Generous cuts all with a minimum of 30 days of maturation',
             items: [{
                 id:87,
                 name: 'Old cow',
                 description: null,
                 price: 28,
             },
             {
                 id:88,
                 name: 'Galician blonde',
                 description: null,
                 price: 32,
             },
             {
                 id:89,
                 name: 'Pink calf',
                 description: null,
                 price: 26,
             },
             {
                 id: 90,
                 name: 'White veal',
                 description: null,
                 price: 25,
             },
             {
                 id:91,
                 name: 'Angus',
                 description: null,
                 price: 28,
             },
             {
                 id:92,
                 name: 'Rib Eye',
                 description: 'approx, 1kg',
                 price: 55,
             },
             {
                 id:93,
                 name: 'Ox',
                 description: 'min, 1kg',
                 price: 120,
                 isUnit: true,
                 unit: 'kg',
             }]
         },
         {
             id:94,
             name: 'Steak',
             description: 'Cuts with a minimum of 1,3Kg selecting the best channels with a filtration that will fascinate you',
             items: [{
                 id:95,
                 name: 'Pink calf',
                 description: null,
                 price: 50,
             },
             {
                 id:96,
                 name: 'White veal',
                 description: null,
                 price: 55,
             },
             {
                 id:97,
                 name: 'T-Bone',
                 description: null,
                 price: 55,
                 isUnit: true,
                 unit: 'kg',

             },
             {
                 id:98,
                 name: 'Galician blonde',
                 description: null,
                 price: 79,
             },
             {
                 id: 99,
                 name: 'Tomahawk',
                 description: null,
                 price: 90,
                 isUnit: true,
                 unit: 'kg',

             },
             {
                 id: 100,
                 name: 'Frisian Holstein',
                 description: null,
                 price: 74,
                 isUnit: true,
                 unit: 'kg',

             },
             {
                 id: 101,
                 name: 'Simmental',
                 description: null,
                 price: 78,
                 isUnit: true,
                 unit: 'kg',

             },
             {
                 id: 102,
                 name: 'Basque cutlet dehesa acorn',
                 description: null,
                 price: 90,
                 isUnit: true,
                 unit: 'kg',
             },
             {
                 id: 103,
                 name: 'Ox',
                 description: null,
                 price: 120,
                 isUnit: true,
                 unit: 'kg',

             }]
         },
         {
             id: 9,
             name: 'Side dishes',
             items: [{
                 id: 104,
                 name: '',
                 price: 3.5,
                 items: [{
                     id: 105,
                     name: 'Pure',
                     description: null,
                 },
                 {
                     id: 106,
                     name: 'Coconut rice with raisins',
                     description: null,
                 },
                 {
                     id: 107,
                     name: 'Roasted potato',
                     description: null,
                 },
                 {
                     id: 108,
                     name: 'Roasted potato with aioli',
                     description: null,
                 },
                 {
                     id: 109,
                     name: 'French fries',
                     description: null,
                 },
                 {
                     id: 110,
                     name: 'Pimentón padrón',
                     description: null,
                 }]
             },
             {
                 id: 111,
                 name: 'Sauteed vegetables with mushrooms and wild mushrooms',
                 description: 'On a plate',
                 price: 5,
             }]
         },
         {
             id: 10,
             name: 'Sauces',
             items: [{
                 id: 112,
                 name: 'Green pepper',
                 description: null,
                 price: 3,
             },
             {
                 id: 113,
                 name: 'Roquefort',
                 description: null,
                 price: 3,
             },
             {
                 id: 114,
                 name: 'Mushrooms',
                 description: null,
                 price: 3,
             },
             {
                 id: 115,
                 name: 'Bearnaise',
                 description: null,
                 price: 3,
             },
             {
                 id: 116,
                 name: 'Chimichurri',
                 description: null,
                 price: 2,
             },
             {
                 id: 117,
                 name: 'Mojo picón',
                 description: null,
                 price: 2,
             },
             {
                 id: 118,
                 name: 'Mild curry',
                 description: null,
                 price: 1,
             },
             {
                 id: 119,
                 name: 'Caesar',
                 description: null,
                 price: 1,
             }]
         }],
     }],
}]