export const postresData = [{
    es: [{
        category: [{
             id: 1,
                name: 'Postres Karnivoros',
                items: [{
                    id: 1,
                    name: 'Tarta de queso',
                    description: 'Cremoso de queso con interior de frambuesa y encamisado de chocolate blanco',
                    price: 6.5,
                },
                {
                    id: 2,
                    name: 'Cremoso Manchego',
                    description: 'Cremoso de queso manchego con mermelada de arandanos, nata y helado de vainilla',
                    price: 7
                },
                {
                    id: 3,
                    name: 'Coulant de Queso Manchego',
                    description: 'Coulant de queso manchego con mermelada de arandanos, nata y helado de vainilla',
                    price: 7
                },
                {
                    id: 4,
                    name: 'Tarta de Toblerone',
                    description: 'Deliciosa tarta de toblerone acompañada de helado de vainilla',
                    price: 7
                },
                {
                    id: 5,
                    name: 'Crema de tocino de cielo',
                    price: 7
                },
                {
                    id: 6,
                    name: 'Lingote de vainilla',
                    description: 'Mousse de vainilla, platano caramelizado, tres leches y chantilly de caramelo',
                    price: 6.9
                },
                {
                    id: 7,
                    name: '3 Chocolates',
                    description: 'Mousse de tres chocolates',
                    price: 6.9
                },
                {
                    id: 8,
                    name: 'Esfera de Frambuesa',
                    description: 'Cremoso de frambuesa con bizcocho crujiente de pistacho',
                    price: 6.9
                },
                {
                    id: 9,
                    name: 'Mango y coco',
                    description: 'Mousse de coco con interior de mango y quenelle de malibú',
                    price: 6.9
                }]
            }]
        }],
    en: [{
        category: [{
            id: 1,
            name: 'Carnivorous Desserts',
            items: [{
                    id: 1,
                    name: 'Cheesecake',
                    description: 'Creamy cheesecake with raspberry filling and white chocolate coating',
                    price: 6.5,
                },
                {
                    id: 2,
                    name: 'Manchego Creamy Cheese',
                    description: 'Creamy manchego cheese with blueberry jam, cream and vanilla ice cream',
                    price: 7
                },
                {
                    id: 3,
                    name: 'Manchego Cheese Coulant',
                    description: 'Manchego cheese coulant with blueberry jam, cream and vanilla ice cream',
                    price: 7
                },
                {
                    id: 4,
                    name: 'Toblerone Cake',
                    description: 'Delicious toblerone cake served with vanilla ice cream',
                    price: 7
                },
                {
                    id: 5,
                    name: 'Tocino de Cielo Cream',
                    price: 7
                },
                {
                    id: 6,
                    name: 'Vanilla Ingot',
                    description: 'Vanilla mousse, caramelized banana, three milk cake and caramel chantilly',
                    price: 6.9
                },
                {
                    id: 7,
                    name: '3 Chocolates',
                    description: 'Three chocolate mousse',
                    price: 6.9
                },
                {
                    id: 8,
                    name: 'Raspberry Sphere',
                    description: 'Raspberry creamy with crunchy pistachio sponge cake',
                    price: 6.9
                },
                {
                    id: 9,
                    name: 'Mango and Coconut',
                    description: 'Coconut mousse with mango filling and malibú quenelle'
                }
            ]
        }]
    }]
}]