export const drinks_data = [{
    es: [{
        category: [{
            id: 1,
            name: 'Nuestros Vermouths ',
            items: [{
                id: 1,
                name: 'Vermouth',
                price: 5.5,
                items: [{
                    id: 1,
                    name: 'Vermouth Petroni Blanco',
                },
                {
                    id: 2,
                    name: 'Vermouth Petroni Rojo',
                },
                {
                    id: 3,
                    name: 'Vermouth Petroni Bitter',
                },
                {
                    id: 4,
                    name: 'Vermouth Cinzano Blanco',
                },
                {
                    id: 5,
                    name: 'Vermouth Cinzano Rojo',
                },
                {
                    id: 6,
                    name: 'Vermouth Bandarra Sangría',
                },
                {
                    id: 7,
                    name: 'Vermouth Bandarra Tinto',
                },
                {
                    id: 8,
                    name: 'Vermouth Bandarra Blanco',
                }]
            },
            {
                id: 2,
                name: '',
                price: 7.5,
                items: [{
                    id: 1,
                    name: 'Vermouth golfo',
                },
                {
                    id: 2,
                    name: 'Vermouth sherry cask Fernando de Castilla',
                },
                {
                    id: 3,
                    name: 'Vermouth blanco Fernando de Castilla'
                },
                {
                    id: 4,
                    name: 'Vermouth lacuesta',
                }]
            }]
        },
        {
            id: 3,
            name: 'Cervezas',
            items: [{
                id: 1,
                name: 'Cerveza Estrella Galicia Especial',
                items: [{
                    id: 1,
                    name: 'Caña',
                    price: 2
                },
                {
                    id: 2,
                    name: 'Copa',
                    price: 4
                }]
            },
            {
                id: 2,
                name: 'Cerveza Estrella Galicia 1906',
                items: [{
                    id: 1,
                    name: 'Caña',
                    price: 2
                },
                {
                    id: 2,
                    name: 'Copa',
                    price: 5
                }]
            },
            {
                id: 3,
                name: 'Tinto de verano La tita',
                items: [{
                    id: 1,
                    name: 'Copa',
                    price: 3.5
                }]
            },
            {
                id: 4,
                name: 'Tinto de verano preparado',
                items: [{
                    id: 1,
                    name: 'Copa',
                    price: 3
                }]
            },
            {
                id: 5,
                name: 'Sangría',
                items: [{
                    id: 1,
                    name: 'Jarra',
                    price: 3.5
                },
                {
                    id: 2,
                    name: 'Copa',
                    price: 3.5
                }]
            },
            {
                id: 6,
                name: 'En botellín',
                items: [{
                    id: 1,
                    name: 'Estrella Galicia Especial',
                    price: 2.5
                },
                {
                    id: 2,
                    name: 'Estrella Galicia sin gluten',
                    price: 2.5
                },
                {
                    id: 3,
                    name: 'Estrella Galicia 0,0',
                    price: 2.5
                },
                {
                    id: 4,
                    name: 'Estrella Galicia 0,0 tostada',
                    price: 2.5
                },
                {
                    id: 5,
                    name: 'Cruzcampo',
                    price: 2.5
                },
                {
                    id: 6,
                    name: 'Tinto de verano La Casera',
                    price: 2.5
                },
                {
                    id: 7,
                    name: 'Ladrón de manzanas',
                    description: 'Copa ',
                    price: 2.5
                }]
            }]
        },
        {
            id: 4,
            name: 'Vinos de Copeo',
            items: [{
                id: 1,
                name: 'Champagne Mumm',
                price: 7
            },
            {
                id: 2,
                name: 'Cava Brut',
                price: 3.5
            },
            {
                id: 3,
                name: 'Rioja Crianza',
                price: 3
            },
            {
                id: 4,
                name: 'Ribera del Duero Crianza',
                price: 3
            },
            {
                id: 5,
                name: 'Vino blanco verdejo',
                price: 3
            },
            {
                id: 6,
                name: 'Olorosos',
                items: [{
                    id: 1,
                    name: 'Fino',
                    price: 2.40
                },
                {
                    id: 2,
                    name: 'Manzanilla',
                    price: 2.40
                },
                {
                    id: 3,
                    name: 'PX',
                    price: 2.40
                }]
            }]
        },
        {
            id: 5,
            name: 'Refrescos',
            items: [{
                id: 1,
                name: 'Cabreiroá',
                items: [{
                    id: 1,
                    name: '1/2L',
                    price: 1.5
                },
                {
                    id: 2,
                    name: '1L',
                    price: 3
                }]
            },
            {
                id: 2,
                name: 'Agua con gas',
                price: 3
            },
            {
                id: 3,
                name: 'Coca Cola',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 2
                },
                {
                    id: 2,
                    name: 'Zero',
                    price: 2
                },
                {
                    id: 3,
                    name: 'Zero light',
                    price: 2
                }]
            },
            {
                id: 4,
                name: 'Aquarius',
                items: [{
                    id: 1,
                    name: 'Naranja',
                    price: 2.2
                },
                {
                    id: 2,
                    name: 'Limón',
                    price: 2.2
                }]
            },
            {
                id: 5,
                name: 'Nestea',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 2.2
                },
                {
                    id: 2,
                    name: 'Maracuya',
                    price: 2.2
                }]
            },
            {
                id: 6,
                name: 'Redbull',
                price: 3
            },
            {
                id: 7,
                name: 'La Casera',
                description: '1L',
                price: 3.2
            },
            {
                id: 8,
                name: 'Pepsi',
                price: 2
            },
            {
                id: 9,
                name: 'Kas Naranja',
                price: 2
            },
            {
                id: 10,
                name: 'Schweppes limón',
                price: 2
            },
            {
                id: 11,
                name: 'Seven up',
                price: 2
            },
            {
                id: 12,
                name: 'Bitter Kas',
                price: 2
            },
            {
                id: 13,
                name: 'Tónica',
                price: 2
            }]
        }]
    }],
    en: [{
        category: [{
            id: 1,
            name: 'Our Vermouths',
            items: [{
                id: 1,
                name: 'Vermouth',
                price: 5.5,
                items: [{
                    id: 1,
                    name: 'Petroni White Vermouth',
                },
                {
                    id: 2,
                    name: 'Red Petroni Vermouth',
                },
                {
                    id: 3,
                    name: 'Petroni Bitter Vermouth',
                },
                {
                    id: 4,
                    name: 'Vermouth Cinzano Blanco',
                },
                {
                    id: 5,
                    name: 'Red Cinzano Vermouth',
                },
                {
                    id: 6,
                    name: 'Vermouth Bandarra Sangria',
                },
                {
                    id: 7,
                    name: 'Vermut Bandarra Tinto',
                },
                {
                    id: 8,
                    name: 'Vermouth Bandarra Blanco',
                }]
            },
            {
                id: 2,
                name: '',
                price: 7.5,
                items: [{
                    id: 1,
                    name: 'Vermouth gulf',
                },
                {
                    id: 2,
                    name: 'Vermut Jerez cask Fernando de Castilla',
                },
                {
                    id: 3,
                    name: 'Fernando de Castilla white vermouth'
                },
                {
                    id: 4,
                    name: 'Vermouth lacuesta',
                }]
            }]
        },
        {
            id: 3,
            name: 'Beers',
            items: [{
                id: 1,
                name: 'Estrella Galicia Special Beer',
                items: [{
                    id: 1,
                    name: 'Cane',
                    price: 2
                },
                {
                    id: 2,
                    name: 'Cup',
                    price: 4
                }]
            },
            {
                id: 2,
                name: 'Estrella Galicia 1906 Beer',
                items: [{
                    id: 1,
                    name: 'Cane',
                    price: 2
                },
                {
                    id: 2,
                    name: 'Cup',
                    price: 5
                }]
            },
            {
                id: 3,
                Name: 'Tinto de verano La Tita',
                items: [{
                    id: 1,
                    name: 'Cup',
                    price: 3.5
                }]
            },
            {
                id: 4,
                name: 'Tinto de verano prepared',
                items: [{
                    id: 1,
                    name: 'Cup',
                    price: 3
                }]
            },
            {
                id: 5,
                name: 'Sangria',
                items: [{
                    id: 1,
                    name: 'Jug',
                    price: 3.5
                },
                {
                    id: 2,
                    name: 'Cup',
                    price: 3.5
                }]
            },
            {
                id: 6,
                name: 'In a bottle',
                items: [{
                    id: 1,
                    name: 'Special Estrella Galicia',
                    price: 2.5
                },
                {
                    id: 2,
                    name: 'Estrella Galicia gluten-free',
                    price: 2.5
                },
                {
                    id: 3,
                    name: 'Estrella Galicia 0,0',
                    price: 2.5
                },
                {
                    id: 4,
                    name: 'Estrella Galicia 0,0 toast',
                    price: 2.5
                },
                {
                    id: 5,
                    name: 'Cruzcampo',
                    price: 2.5
                },
                {
                    id: 6,
                    name: 'Tinto de verano La Casera',
                },
                {
                    id: 4,
                    name: 'Ribera del Duero Crianza',
                    price: 3
                },
                {
                    id: 5,
                    name: 'Verdejo white wine',
                    price: 3
                },
                {
                    id: 6,
                    name: 'Smelly',
                    items: [{
                        id: 1,
                        name: 'Fine',
                        price: 2.40
                    },
                    {
                        id: 2,
                        name: 'Chamomile',
                        price: 2.40
                    },
                    {
                        id: 3,
                        name: 'PX',
                        price: 2.40
                    }]
                }]
            },
            {
                id: 5,
                name: 'Soft Drinks',
                items: [{
                    id: 1,
                    name: 'Cabreiroá',
                    items: [{
                        id: 1,
                        name: '1/2L',
                        price: 1.5
                    },
                    {
                        id: 2,
                        name: '1L',
                        price: 3
                    }]
                },
                {
                    id: 2,
                    name: 'Sparkling water',
                    price: 3
                },
                {
                    id: 3,
                    name: 'Coca-Cola',
                    items: [{
                        id: 1,
                        name: 'Normal',
                        price: 2
                    },
                    {
                        id: 2,
                        name: 'Zero',
                        price: 2
                    },
                    {
                        id: 3,
                        name: 'Zerolight',
                        price: 2
                    }]
                },
                {
                    id: 4,
                    name: 'Aquarius',
                    items: [{
                        id: 1,
                        name: 'Orange',
                        price: 2.2
                    },
                    {
                        id: 2,
                        name: 'Lemon',
                        price: 2.2
                    }]
                },
                {
                    id: 5,
                    name: 'Nestea',
                    items: [{
                        id: 1,
                        name: 'Normal',
                        price: 2.2
                    },
                    {
                        id: 2,
                        name: 'Maracuya',
                        price: 2.2
                    }]
                },
                {
                    id: 6,
                    name: 'Redbull',
                    price: 3
                },
                {
                    id: 7,
                    name: 'La Casera',
                    description: '1L',
                    price: 3.2
                },
                {
                    id: 8,
                    name: 'Pepsi',
                    price: 2
                },
                {
                    id: 9,
                    name: 'Kas Orange',
                    price: 2
                },
                {
                    id: 10,
                    name: 'Schweppes lemon',
                    price: 2
                },
                {
                    id: 11,
                    name: 'Seven up',
                    price: 2
                },
                {
                    id: 12,
                    name: 'Bitter Kas',
                    price: 2
                },
                {
                    id: 13,
                    name: 'Tonic',
                    price: 2
                }]
            }]
        }]
    }]
}]
