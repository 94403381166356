import React, { useEffect } from 'react'
import { Accordions } from '../../components/accordion/Accordions'
import { vinosData } from '../../data/carta/vinos.js'
import { MenuDropdown } from '../../components/menu/MenuDropdown';

export const Vinos = () => {
    const lang = localStorage.getItem('lang') || 'es';
    const data = {
        es: {
            title: 'Vinos - Karnivoro Restaurant',
        },
        en: {
            title: 'Wines - Karnivoro Restaurant',
        }
    }

    document.title = data.es.title;
    useEffect(() => {
        window.location.href = "https://app.okeymenu.com/lSmSoq/es";
      }, []);
  return (
    <div>
        <div className='text-center'>  
            <MenuDropdown />   
        </div>
        {/* <div className='mt-4'>
            <h1 className='text-center text-uppercase'><b>{data.es.title}</b></h1>
        </div> */}
        <div className='mt-4'> 
            <Accordions data={vinosData} lang={lang} />
        </div>
    </div>
  )
}
