export const combinadosData = [{
    es: [{
        category: [{
            id: 1,
            name: 'Tónicas Premium',
            items: [{
                id: 1,
                name: 'Feber Tree',
                price: 2.5,
            }, {
                id: 2,
                name: 'Schweppes Pimienta Rosa',
                price: 2.5,
            }]
        },
        {
            id: 2,
            name: 'Vodka',
            items: [{
                id: 1,
                name: 'Absolut',
                price: 7,
            }, {
                id: 2,
                name: 'Smirnoff',
                price: 7,
            }, {
                id: 3,
                name: 'Ciroc',
                price: 10,
            }, {
                id: 4,
                name: 'Ciroc Berry',
                price: 10,
            }, {
                id: 5,
                name: 'Ciroc Coconut',
                price: 10,
            }, {
                id: 6,
                name: 'Ciroc Pineapple',
                price: 10,
            }, {
                id: 7,
                name: 'Ciroc Peach',
                price: 10,
            }, {
                id: 8,
                name: 'Ciroc Apple',
                price: 10,
            }]
        },
        {
            id: 3,
            name: 'Ginebra',
            items: [{
                id: 1,
                name: 'Tanqueray',
                items: [{
                    id: 1,
                    name: '0,0',
                    price: 7,
                }, {
                    id: 2,
                    name: 'Normal',
                    price: 7,
                },
                {
                    id: 3,
                    name: 'Lima',
                    price: 7,
                },
                {
                    id: 4,
                    name: 'Ten',
                    price: 7,
                }]
            }, 
            {
                id: 2,
                name: 'Larios',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 7,
                },
                {
                    id: 2,
                    name: 'Rosé',
                    price: 7,
                },
                {
                    id: 3,
                    name: '12',
                    price: 8,
                }]
            },
            {
                id: 3,
                name: 'Beefeater',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 7,
                },
                {
                    id: 2,
                    name: 'Light',
                    price: 7,
                },
                {
                    id: 3,
                    name: 'Pink',
                    price: 7,
                }],
            },
            {
                id: 4,
                name: 'Bulldog',
                price: 8,
            },
            {
                id: 5,
                name: 'Nordes',
                price: 8,
            },
            {
                id: 6,
                name: 'Brockmans',
                price: 10
            },
            {
                id: 7,
                name: 'Martin Miller´s',
                price: 10
            },
            {
                id: 8,
                name: 'Seagams',
                price: 7
            },
            {
                id: 9,
                name: 'Gin MG',
                price: 7
            },
            {
                id: 10,
                name: 'Rives',
                items: [{
                    id: 1,
                    name: 'MG',
                    price: 7,
                },
                {
                    id: 2,
                    name: '1880',
                    price: 7,
                },
                {
                    id: 3,
                    name: 'Pink',
                    price: 7,
                },
                {
                    id: 4,
                    name: 'Mediterranea',
                    price: 7,
                },
                {
                    id: 5,
                    name: 'Exótica',
                    price: 7,
                },
                {
                    id: 6,
                    name: 'Especial',
                    price: 8
                },
                {
                    id: 7,
                    name: 'Alambrique',
                    price: 9
                },
                {
                    id: 8,
                    name: 'Sweet Spirit',
                    price: 9
                }]
            },
            {
                id: 11,
                name: 'Malfi',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 9,
                },
                {
                    id: 2,
                    name: 'Limón',
                    price: 10,
                },
                {
                    id: 3,
                    name: 'Hendrick´s',
                    price: 10,
                }]
            }]
        },
        {
            id: 4,
            name: 'Tequila',
            items: [{   
                id: 1,
                name: 'Jose Cuervo Blanco',
                price: 7.5,
            },
            {
                id: 2,
                name: 'Jose Cuervo Reposado',
                price: 8
            },
            {
                id: 3,
                name: 'Patrón',
                items: [{
                    id: 1,
                    name: 'Silver',
                    price: 10,
                },
                {
                    id: 2,
                    name: 'Reposado',
                    price: 12,
                },
                {
                    id: 3,
                    name: 'Reposado Reserva',
                    price: 15
                }]
            },
            {
                id: 4,
                name: 'Don Julio Reserva',
                price: 10
            },
            {
                id: 5,
                name: '99000 Horas',
                price: 12
            },
            {
                id: 6,
                name: 'Tequila Zignum Mezcal Reposado',	
                price: 10
            }]
        },
        {
            id: 5,
            name: 'Whisky',
            items: [{
                id: 1,
                name: 'Dyc 8 años',
                price: 7,
            },
            {
                id: 2,
                name: 'Ballantines',
                price: 7,
            },
            {
                id: 3,
                name: 'JB',
                price: 7,
            },
            {
                id: 4,
                name: 'Baha',
                price: 10,
            },
            {
                id: 5,
                name: 'Johnnie Walker',
                items: [{
                    id: 1,
                    name: 'Red Label',
                    price: 7,
                },
                {
                    id: 2,
                    name: 'Black Label',    
                    price: 9
                },
                {
                    id: 3,
                    name: 'Double black',
                    price: 14
                },
                {
                    id: 4,
                    name: 'Green Label',
                    price: 16
                },
                {
                    id: 5,
                    name: 'Gold Label',
                    price: 18
                },
                {
                    id: 6,
                    name: 'Blue Label',
                    price: 45
                }]
            },
            {
                id: 6,
                name: 'Chivas',
                items: [{
                    id: 1,
                    name: '12',
                    price: 9,
                },
                {
                    id: 2,
                    name: 'XV',
                    price: 12
                },
                {
                    id: 3,
                    name: '18',
                    price: 16
                },{
                    id: 4,
                    name: 'Royal Salute',
                    price: 30
                }]
            },
            {
                id: 7,
                name: 'Four Roses',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 7,
                },
                {
                    id: 2,
                    name: 'Small Batch',
                    price: 15
                },
                {
                    id: 3,
                    name: 'Single Barrels',
                    price: 16
                }]
            },
            {
                id: 8,
                name: 'Cardhu 12',
                price: 9
            },
            {
                id: 9,
                name: 'Jack Daniel´s',
                price: 8
            },
            {
                id: 10,
                name: 'Talisker 12',
                price: 15
            },
            {
                id: 11,
                name: 'The Glen Livet 12',
                price: 16
            },
            {
                id: 12,
                name: 'Jameson',
                price: 7
            },
            {
                id: 13,
                name: 'Jameson Black Barrel',
                price: 8    
            }]
        },
        {
            id: 6,
            name: 'Ron',
            items: [{
                id: 1,
                name: 'Bacardi superior',
                price: 7,
            },
            {
                id: 2,
                name: 'Brugal añejo',
                price: 7
            },
            {
                id: 3,
                name: 'Cacique',
                price: 7
            },
            {
                id: 4,
                name: 'Cacique 500',
                price: 8
            },
            {
                id: 5,
                name: 'Capitan Morgan',
                price: 8
            },
            {
                id: 6,
                name: 'Conde de Cuba 11 años',
                price: 18
            },
            {
                id: 7,
                name: 'Conde de Cuba 15 años',
                price: 25
            },
            {
                id: 8,
                name: 'Don Papa',
                price: 16
            },
            {
                id: 9,
                name: 'Havana Club',
                items: [{
                    id: 1,
                    name: 'Ritual',
                    price: 7,
                },
                {
                    id: 2,
                    name: 'Club 7',
                    price: 8.5
                },
                {
                    id: 3,
                    name: 'Club Seleccion Maestro',
                    price: 12
                }]
            },
            {
                id: 10,
                name: 'Legendario',
                price: 7
            },
            {
                id: 11,
                name: 'Matusalem 15 años',
                price: 12
            },
            {
                id: 12,
                name: 'Zacapa Gran Reserva',
                price: 20
            },
            {
                id: 13,
                name: 'Zacapa Solera 23 años',
                price: 25
            },
            {
                id: 14,
                name: 'Barceló',
                price: 7
            },
            {
                id: 15,
                name: 'Ron Malibu',
                price: 7
            },
            {
                id: 16,
                name: 'Ron Abuelo',
                items: [{
                    id: 1,
                    name: '12 años',
                    price: 10
                },
                {
                    id: 2,
                    name: 'Centuria',
                    price: 18  
                },
                {
                    id: 3,
                    name: 'Two Oaks',
                    price: 15
                }]
            }]
        },
        {
            id: 7,
            name: 'Licores',
            items: [{
                id: 1,
                name: 'Licores con alcohol',
                items: [{
                    id: 1,
                    name: 'Licor 43',
                    price: 5
                },
                {
                    id: 2,
                    name: 'Baileys Original',
                    price: 5
                },
                {
                    id: 3,
                    name: 'Licor de Crema',
                    price: 4
                },
                {
                    id: 4,
                    name: 'Tía María',
                    price: 5
                },
                {
                    id: 5,
                    name: 'Pacharan',
                    price: 5
                },
                {
                    id: 6,
                    name: 'Licor de Hierbas',
                    price: 4
                },
                {
                    id: 7,
                    name: 'Vodka caramelo',
                    price: 4
                },
                {
                    id: 8,
                    name: 'Pernot Ricard',  
                    price: 5
                },
                {
                    id: 9,
                    name: 'Campari',
                    price: 5
                },
                {
                    id: 10,
                    name: 'Limonchelo',
                    price: 4
                },
                {
                    id: 11,
                    name: 'Jaggermeister',
                    price: 7
                }]
            },
            {
                id: 2,
                name: 'Licores sin alcohol',
                price: 4,
                items: [{
                    id: 1,
                    name: 'Mora',
                },
                {
                    id: 2,
                    name: 'Manzana',
                },
                {
                    id: 3,
                    name: 'Lima',
                },
                {
                    id: 4,
                    name: 'Melocotón',
                },
                {
                    id: 5,
                    name: 'Avellana',
                },
                {
                    id: 6,
                    name: 'Blue Tropic'
                },
                {
                    id: 7,
                    name: 'Kiwi'
                },
                {
                    id: 8,
                    name: 'Granadina'
                }]
            }] 
        }]
    }],
    en: [{
        category: [{
            id: 1,
            name: 'Premium Tonics',
            items: [{
                id: 1,
                name: 'Feber Tree',
                price: 2.5,
            }, {
                id: 2,
                name: 'Schweppes Pink Pepper',
                price: 2.5,
            }]
        },
        {
            id: 2,
            name: 'Vodka',
            items: [{
                id: 1,
                name: 'Absolute',
                price: 7.
            }, {
                id: 2,
                name: 'Smirnoff',
                price: 7.
            }, {
                id: 3,
                name: 'Ciroc',
                price: 10,
            }, {
                id: 4,
                name: 'Ciroc Berry',
                price: 10,
            }, {
                id: 5,
                name: 'Ciroc Coconut',
                price: 10,
            }, {
                id: 6,
                name: 'Ciroc Pineapple',
                price: 10,
            }, {
                id:7,
                name: 'Ciroc Peach',
                price: 10,
            }, {
                id:8,
                name: 'Ciroc Apple',
                price: 10,
            }]
        },
        {
            id: 3,
            name: 'Geneva',
            items: [{
                id: 1,
                name: 'Tanqueray',
                items: [{
                    id: 1,
                    name: '0,0',
                    price: 7.
                }, {
                    id: 2,
                    name: 'Normal',
                    price: 7.
                },
                {
                    id: 3,
                    name: 'Lima',
                    price: 7.
                },
                {
                    id: 4,
                    name: 'Here',
                    price: 7.
                }]
            },
            {
                id: 2,
                name: 'Larios',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 7.
                },
                {
                    id: 2,
                    name: 'Rosé',
                    price: 7.
                },
                {
                    id: 3,
                    name: '12',
                    price: 8.
                }]
            },
            {
                id: 3,
                name: 'Beefeater',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 7.
                },
                {
                    id: 2,
                    name: 'Light',
                    price: 7.
                },
                {
                    id: 3,
                    name: 'Pink',
                    price: 7.
                }],
            },
            {
                id: 4,
                name: 'Bulldog',
                price: 8.
            },
            {
                id: 5,
                name: 'Nordes',
                price: 8.
            },
            {
                id: 6,
                name: 'Brockmans',
                price: 10
            },
            {
                id:7,
                name: 'Martin Millers',
                price: 10
            },
            {
                id:8,
                name: 'Seagams',
                price: 7
            },
            {
                id: 9,
                name: 'Gin MG',
                price: 7
            },
            {
                id: 10,
                name: 'Rives',
                items: [{
                    id: 1,
                    name: 'MG',
                    price: 7.
                },
                {
                    id: 2,
                    name: '1880',
                    price: 7.
                },
                {
                    id: 3,
                    name: 'Pink',
                    price: 7.
                },
                {
                    id: 4,
                    name: 'Mediterranean',
                    price: 7.
                },
                {
                    id: 5,
                    name: 'Exotic',
                    price: 7.
                },
                {
                    id: 6,
                    name: 'Special',
                    price: 8
                },
                {
                    id:7,
                    name: 'Wire',
                    price: 9
                },
                {
                    id:8,
                    name: 'Sweet Spirit',
                    price: 9
                }]
            },
            {
                id: 11,
                name: 'Malfi',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 9.
                },
                {
                    id: 2,
                    name: 'Lemon',
                    price: 10,
                },
                {
                    id: 3,
                    name: 'Hendricks',
                    price: 10,
                }]
            }]
        },
        {
            id: 4,
            name: 'Tequila',
            items: [{
                id: 1,
                name: 'Jose Cuervo Blanco',
                price: 7.5,
            },
            {
                id: 2,
                name: 'Jose Cuervo Reposado',
                price: 8
            },
            {
                id: 3,
                name: 'Pattern',
                items: [{
                    id: 1,
                    name: 'Silver',
                    price: 10,
                },
                {
                    id: 2,
                    name: 'Reposado',
                    price: 12.
                },
                {
                    id: 3,
                    name: 'Reposado Reserva',
                    price: 15
                }]
            },
            {
                id: 4,
                name: 'Don Julio Reserva',
                price: 10
            },
            {
                id: 5,
                name: '99000 Hours',
                price: 12
            },
            {
                id: 6,
                name: 'Zignum Mezcal Reposado Tequila',
                price: 10
            }]
        },
        {
            id: 5,
            name: 'Whiskey',
            items: [{
                id: 1,
                name: 'Dyc 8 years',
                price: 7.
            },
            {
                id: 2,
                name: 'Ballantines',
                price: 7.
            },
            {
                id: 3,
                name: 'JB',
                price: 7.
            },
            {
                id: 4,
                name: 'Baha',
                price: 10,
            },
            {
                id: 5,
                name: 'Johnnie Walker',
                items: [{
                    id: 1,
                    name: 'Red Label',
                    price: 7.
                },
                {
                    id: 2,
                    name: 'Black Label',
                    price: 9
                },
                {
                    id: 3,
                    name: 'Double black',
                    price: 14
                },
                {
                    id: 4,
                    name: 'Green Label',
                    price: 16
                },
                {
                    id: 5,
                    name: 'Gold Label',
                    price: 18
                },
                {
                    id: 6,
                    name: 'Blue Label',
                    price: 45
                }]
            },
            {
                id: 6,
                name: 'Chivas',
                items: [{
                    id: 1,
                    name: '12',
                    price: 9.
                },
                {
                    id: 2,
                    name: 'XV',
                    price: 12
                },
                {
                    id: 3,
                    name: '18',
                    price: 16
                },{
                    id: 4,
                    name: 'Royal Salute',
                    price: 30
                }]
            },
            {
                id:7,
                name: 'Four Roses',
                items: [{
                    id: 1,
                    name: 'Normal',
                    price: 7.
                },
                {
                    id: 2,
                    name: 'Small Batch',
                    price: 15
                },
                {
                    id: 3,
                    name: 'Single Barrels',
                    price: 16
                }]
            },
            {
                id:8,
                name: 'Cardhu 12',
                price: 9
            },
            {
                id: 9,
                name: 'Jack Daniels',
                price: 8
            },
            {
                id: 10,
                name: 'Talisker 12',
                price: 15
            },
            {
                id: 11,
                name: 'The Glen Livet 12',
                price: 16
            },
            {
                id: 12,
                name: 'Jameson',
                price: 7
            },
            {
                id: 13,
                name: 'Jameson Black Barrel',
                price: 8
            }]
        },
        {
            id: 6,
            name: 'Ron',
            items: [{
                id: 1,
                name: 'Bacardi superior',
                price: 7.
            },
            {
                id: 2,
                name: 'Aged Brugal',
                price: 7
            },
            {
                id: 3,
                name: 'Chieftain',
                price: 7
            },
            {
                id: 4,
                name: 'Chieftain 500',
                price: 8
            },
            {
                id: 5,
                name: 'Captain Morgan',
                price: 8
            },
            {
                id: 6,
                name: 'Count of Cuba 11 years old',
                price: 18
            },
            {
                id:7,
                name: 'Count of Cuba 15 years',
                price: 25
            },
            {
                id:8,
                name: 'Don Papa',
                price: 16
            },
            {
                id: 9,
                name: 'Havana Club',
                items: [{
                    id: 1,
                    name: 'Ritual',
                    price: 7.
                },
                {
                    id: 2,
                    name: 'Club 7',
                    price: 8.5
                },
                {
                    id: 3,
                    name: 'Master Selection Club',
                    price: 12
                }]
            },
            {
                id: 10,
                name: 'Legendary',
                price: 7
            },
            {
                id: 11,
                name: 'Methuselah 15 years',
                price: 12
            },
            {
                id: 12,
                name: 'Zacapa Gran Reserva',
                price: 20
            },
            {
                id: 13,
                name: 'Zacapa Solera 23 years',
                price: 25
            },
            {
                id: 14,
                name: 'Barceló',
                price: 7
            },
            {
                id: 15,
                name: 'Malibu Rum',
                price: 7
            },
            {
                id: 16,
                name: 'Ron Abuelo',
                items: [{
                    id: 1,
                    name: '12 years',
                    price: 10
                },
                {
                    id: 2,
                    name: 'Century',
                    price: 18
                },
                {
                    id: 3,
                    name: 'Two Oaks',
                    price: 15
                }]
            }]
        },
        {
            id:7,
            name: 'Liquors',
            items: [{
                id: 1,
                name: 'Alcoholic liqueurs',
                items: [{
                    id: 1,
                    name: 'Liquor 43',
                    price: 5
                },
                {
                    id: 2,
                    name: 'Baileys Original',
                    price: 5
                },
                {
                    id: 3,
                    name: 'Cream Liqueur',
                    price: 4
                },
                {
                    id: 4,
                    name: 'Aunt Mary',
                    price: 5
                },
                {
                    id: 5,
                    name: 'Pacharan',
                    price: 5
                },
                {
                    id: 6,
                    name: 'Herbal Liqueur',
                    price: 4
                },
                {
                    id:7,
                    name: 'Caramel Vodka',
                    price: 4
                },
                {
                    id:8,
                    name: 'Pernot Ricard',
                    price: 5
                },
                {
                    id: 9,
                    name: 'Campari',
                    price: 5
                },
                {
                    id: 10,
                    name: 'Lemonchelo',
                    price: 4
                },
                {
                    id: 11,
                    name: 'Jaggermeister',
                    price: 7
                }]
            },
            {
                id: 2,
                name: 'Non-alcoholic spirits',
                price: 4,
                items: [{
                    id: 1,
                    name: 'Mora',
                },
                {
                    id: 2,
                    name: 'Apple',
                },
                {
                    id: 3,
                    name: 'Lima',
                },
                {
                    id: 4,
                    name: 'Peach',
                },
                {
                    id: 5,
                    name: 'Hazelnut',
                },
                {
                    id: 6,
                    name: 'Blue Tropic'
                },
                {
                    id:7,
                    name: 'Kiwi'
                },
                {
                    id:8,
                    name: 'Grenadine'
                }]
            }]
        }]
    }]
}]