import React from 'react'
import { Carousel } from 'react-bootstrap'
import logo from '../../assets/img/logo-karnivoro-blanco.png';

export const Slider = ({img}) => {
  return (
    <div>
        <Carousel controls={false}>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={img}
                alt="First slide"
                />
                <Carousel.Caption>
                {/* <p className='description-slider'>EN ALHAURIN DE LA TORRE, MÁLAGA</p> */}
                {/* <h1 className='title-slider'>Karnivoro Restaurant</h1> */}
                {/* <img className='mt-4 title-slider' src={logo} alt="logo" width={250}/> */}
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
    )
}
