import bcrypt from "bcryptjs";

// create auth users
const authUsers = [
  {
    id: 1,
    name: "admin",
    password: "admin",
  },
  {
    id: 2,
    name: "user",
    password: "user",
  },
];

// Función para hashear la contraseña antes de guardarla
const hashPassword = (password) => {
  const salt = bcrypt.genSaltSync(10);
  return bcrypt.hashSync(password, salt);
};

// Hasheamos las contraseñas antes de guardarlas
authUsers.forEach((user) => {
  user.password = hashPassword(user.password);
});

export { authUsers };
