import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../context/LanguageProvider';

export const Accordions = ({data}) => {
    
    const { lang } = useLanguage();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='align-items-center mt-4'>
        <img className='decor-image' src={'https://selvaticarestaurant.com/wp-content/uploads/2021/11/hoja1.svg'} alt="hoja" />
        <img className='decor-image2' src={'https://selvaticarestaurant.com/wp-content/uploads/2021/11/hoja2.svg'} alt="hoja" />
        {data.map((accordion) => (
            accordion?.[lang]?.map((acc) => {
                return(
                acc.category.map((cat) => {
                    return (
                        <div key={cat.id} className="mt-1">
                            <h1 className='cart-title text-center'>
                                {cat.name}
                                <h6 className='mt-4'>{cat.description}</h6>
                            </h1>
                            
                            <div>
                                {cat.items.map((item) => {
                                    // add zero to number with one decimal
                                    const prices = item.price?.toFixed(2);
                                    return (
                                        <div key={item.id} className="text-center">
                                            <span className={
                                                item.items ? 'cart-subtitle2' : 'cart-subtitle'
                                            }>
                                            {item.name}
                                                {item.icon && 
                                                    <img src={item.icon} alt={item.name} width="20"/>
                                                }  
                                            </span>
                                            <div>
                                                <p className='cart-description'>
                                                    <i>{item.description}</i>
                                                </p>  
                                                {item.items && item.items.map((item) => {
                                                    const prices = item.price?.toFixed(2);
                                                    return (
                                                        <div key={item.id}>
                                                            <span className='cart-subtitle'>
                                                                {item.name}
                                                            </span>
                                                            {item.icon &&
                                                                    <img src={item.icon} alt={item.name} width="20"/>
                                                                }  
                                                                <span className='cart-description'>
                                                                    <i>{item.description}</i>  
                                                                </span>
                                                                <p className='cart-price'>
                                                                    {prices} {!prices ? null : '€'}
                                                                    {item.isUnit && <span className='cart-unit'>{item.numberUnit ? <span>{' - '}{item.numberUnit }</span> : null}{'  '}/{item.unit ? <span>{item.unit}</span> : 'ud'}</span>}
                                                                </p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <p className='cart-price'>
                                                {prices} {!prices ? null : '€'}
                                                {item.isUnit && <span className='cart-unit'>{item.numberUnit ? <span>{' - '}{item.numberUnit }</span> : null}{'  '}/{item.unit ? <span>{item.unit}</span> : 'ud'}</span>}
                                            </p>
                                            <hr style={{color: '#D28959', zIndex: 1}}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })
            )})
        ))}
    </div>
  )
}
