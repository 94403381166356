import React, { useState } from "react";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo-karnivoro-blanco.png";
import { authUsers } from "../../data/auth_login";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    const user = authUsers.find((user) => user.name === email);
    if (user) {
      if (bcrypt.compareSync(password, user.password)) {
        // Set cookie with the user's name
        Cookies.set("user", user.name, { expires: 1 }); // Expires in 1 day
        navigate("/");
      } else {
        alert("Contraseña incorrecta");
      }
    } else {
      alert("Usuario no encontrado");
    }
  };


  return (
    <div className="bg-home">
      {/* Your existing content */}
      <header className="banner">
        {/* Your existing content */}
      </header>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6}>
            <div className="login-form">
              <img src={logo} alt="logo karnivoro" width="150" />
              <h2 className="text-center title-home text-uppercase mt-2 font-weight-700">
                Iniciar sesión
              </h2>
              <Form>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Ingresa tu correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Ingresa tu contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  className="mt-2 text-uppercase text-light btn-cart"
                  onClick={handleLogin}
                >
                  Ingresar
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Your existing content */}
    </div>
  );
};
