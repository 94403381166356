import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/img/logo-karnivoro-blanco.png";
import packageJson from "../../../package.json";
import { useLanguage } from "../../context/LanguageProvider";

export const Footer = () => {
  const { lang } = useLanguage();
  const year = new Date().getFullYear();
  const version = packageJson.version;

  const horario = {
    lunes: "13:00–16:00",
    martes: "Cerrado",
    miércoles: "13:00–16:00, 19:30–24:00",
    jueves: "13:00–16:00, 19:30–24:00",
    viernes: "13:00–16:00, 19:30–24:00",
    sábado: "13:00–16:00, 19:30–24:00",
    domingo: "13:00–16:00, 19:30–24:00",
  };

  const langData = {
    es: {
      title: "Carta - Karnivoro Restaurant",
      contact: "Contacto",
      address: "Dirección",
      schedule: "Horario",
    },
    en: {
      title: "Menu - Karnivoro Restaurant",
      contact: "Contact",
      address: "Address",
      schedule: "Schedule",
    },
  };

  return (
    <MDBFooter className="bg-home text-white text-center text-md-start">
      <Container className="p-4 footer">
        <Row>
          <Col lg="3" md="12" className="mb-4 mb-md-0">
            <img className="mt-4" src={logo} alt="logo" width={250} />
            <p style={{ color: "#b87456" }}>
              <b>ALHAURIN DE LA TORRE</b>
            </p>
          </Col>
          <Col lg="4" md="6" className="mb-4 mb-md-0">
            <ul className="list-unstyled">
              <li className="mt-3">
                <b>{langData[lang].contact}</b>
                <br />
                <a href="tel:+34 722 835 293">+34 722 835 293</a>
                <br />
                <a href="mailto:karnivoromalaga@gmail.com">
                  karnivoromalaga@gmail.com
                </a>
              </li>
              <li className="mt-3">
                <b>{langData[lang].address}</b>
                <br />
                <a href="https://goo.gl/maps/X7oYzMqgvsqbyU8y5">
                  Av. Reyes Católicos N10 29130 Alhaurín de la Torre, España
                </a>
              </li>
              <li className="mt-3">
                <b>{langData[lang].schedule}</b>
                <ul style={{ color: "#D28959", textAlign: "left" }}>
                  {Object.keys(horario).map((key) => (
                    <li key={key}>
                      <span style={{ color: "#FFFFFF" }}>{key}:</span>{" "}
                      <span style={{ color: horario[key] === "Cerrado" ? "red" : "#D28959" }}>
                        {horario[key]}
                      </span>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </Col>
          <Col lg="5" md="6" className="mb-4 mb-md-0">
            <ul className="list-unstyled">
              <li className="mt-3">
                <iframe
                  title="maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d672.840665327968!2d-4.566945945971548!3d36.660370031999584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd72e54617e3c753%3A0x8883c4cb28cea6c0!2sKarnivoro%20Restaurant!5e0!3m2!1ses!2ses!4v1678813625334!5m2!1ses!2ses"
                  width="300"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>

      <div className="text-black text-center p-3 bg-light footer">
        © {year} Copyright by{" "}
        <a className="text-primary" href="https://aleburgos.es">
          Sawer Corp
        </a>{" "}
        All rights reserved.
      </div>
      <div className="text-black text-center p-0 bg-light footer">
        <small className="text-center " style={{ color: "#b874565e" }}>
          version {version}
        </small>
      </div>
    </MDBFooter>
  );
};
