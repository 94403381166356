import dinner from "../assets/icons/dinner.png";
import drink from "../assets/icons/drink.png";
import pastel from "../assets/icons/pastel.png";
import toast from "../assets/icons/toast.png";
import wine from "../assets/icons/wine.png";

export const buttonsFood = [
  {
    es: [
      {
        id: 1,
        name: "Principal",
        icon: dinner,
        url: "/principal",
      },
      {
        id: 2,
        name: "Bebidas",
        icon: drink,
        url: "/bebidas",
      },
      {
        id: 3,
        name: "Postres",
        icon: pastel,
        url: "/postres",
      },
      {
        id: 4,
        name: "Vinos",
        icon: wine,
        url: "/vinos",
      },
      {
        id: 5,
        name: "Combinados",
        icon: toast,
        url: "/combinados",
      },
    ],
    en: [
      {
        id: 1,
        name: "Main",
        icon: dinner,
        url: "/principal",
      },
      {
        id: 2,
        name: "Drinks",
        icon: drink,
        url: "/bebidas",
      },
      {
        id: 3,
        name: "Desserts",
        icon: pastel,
        url: "/postres",
      },
      {
        id: 4,
        name: "Wines",
        icon: wine,
        url: "/vinos",
      },
      {
        id: 5,
        name: "Combos",
        icon: toast,
        url: "/combinados",
      },
    ],
  },
];
