import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { VscThreeBars } from 'react-icons/vsc';
import { MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo-karnivoro.png';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import flagEs from '../../assets/icons/es.png';
import flagEn from '../../assets/icons/en.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { useLanguage } from '../../context/LanguageProvider';

const LanguageSelector = () => {

  const {lang, changeLanguage} = useLanguage();

  return (
    <Dropdown drop="start">
      <Dropdown.Toggle variant="link" id="dropdown-basic" direction="left">
        <img src={lang === 'es' ? flagEs : flagEn} alt={lang === 'es' ? 'Español' : 'English'} width="24" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage('es')}>
          <img src={flagEs} alt="Español" width="24" />
          <span style={{ marginLeft: '0.5rem' }}>Español</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage('en')}>
          <img src={flagEn} alt="English" width="24" />
          <span style={{ marginLeft: '0.5rem' }}>English</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export const MenuDropdown = () => {
  const [language, setLanguage] = useState('es');


  // get path name
  const navigate = useNavigate();

  const location = useLocation();
  const path = location.pathname;

  // split path name
  const pathArray = path.split('/');

  const [isHidden, setIsHidden] = useState(false);

  const hideBar = () => {
    const pos = window.pageYOffset;
    pos > 120 ? setIsHidden(true) : setIsHidden(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", hideBar, { passive: true });

    return () => {
      window.removeEventListener("scroll", hideBar);
    };
  }, []);

  let classHide = isHidden ? "topbar fixed" : "topbar";

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  }

  return (
    <Navbar className={classHide + " topbar"} expand="lg">
      <Container>
        <Navbar.Brand onClick={() => { navigate('/') }}>
          <img
            src={logo}
            width="200"
            className="d-inline-block text-center align-top"
            alt="Logo"
          />
        </Navbar.Brand>

        <div className="d-flex align-items-center"> {/* Nuevo contenedor d-flex */}
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <VscThreeBars />
          </Navbar.Toggle>
          <LanguageSelector onChangeLanguage={changeLanguage} currentLanguage={language} />
        </div>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => { navigate('/') }} className={`nav-link ${pathArray[1] === '' ? 'active' : ''}`}>Inicio</Nav.Link>           
            <Nav.Link onClick={() => { navigate('/principal') }} className={`nav-link ${pathArray[1] === 'principal' ? 'active' : ''}`}>Principal</Nav.Link>
            <Nav.Link onClick={() => { navigate('/bebidas') }} className={`nav-link ${pathArray[1] === 'bebidas' ? 'active' : ''}`}>Bebidas</Nav.Link>
            <Nav.Link onClick={() => { navigate('/postres') }} className={`nav-link ${pathArray[1] === 'postres' ? 'active' : ''}`}>Postres</Nav.Link>
            <Nav.Link onClick={() => { navigate('/vinos') }} className={`nav-link ${pathArray[1] === 'vinos' ? 'active' : ''}`}>Vinos</Nav.Link>
            <Nav.Link onClick={() => { navigate('/combinados') }} className={`nav-link ${pathArray[1] === 'combinados' ? 'active' : ''}`}>Combinados</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
