import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Slider } from "../components/slider/Slider";
import slider1 from "./../assets/img/slider1.jpg";
import { buttonsFood } from "../data/buttons_food";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo-karnivoro-blanco.png";
import whatsapp from "../assets/icons/whatsapp.png";
import img1 from "../assets/img/img1.jpg";
import img2 from "../assets/img/img2.jpg";
import img3 from "../assets/img/img3.jpg";
import img4 from "../assets/img/compressimg/56-min.png";
import img5 from "../assets/img/compressimg/232-min.png";
import img6 from "../assets/img/compressimg/453-min.png";
import img7 from "../assets/img/compressimg/5-min.png";
import img8 from "../assets/img/compressimg/2223-min.png";
import img9 from "../assets/img/compressimg/2324-min.png";
import img10 from "../assets/img/compressimg/7777-min.png";
import img11 from "../assets/img/compressimg/77777-min.png";
import img12 from "../assets/img/compressimg/1111-min.png";

import kwhite from "../assets/img/karnivoro-white.png";

import { useLanguage } from "../context/LanguageProvider";

import video from "../assets/video/banner.mp4";
import { MenuDropdown } from "../components/menu/MenuDropdown";

export const Home = () => {
  const { lang } = useLanguage();
  const languageData = {
    es: {
      cartTitle: "Carta",
      bookTitle: "Reserva",
      homeTitle: "La mejor experiencia gastronómica y ocio se llama Karnivoro",
      homeSubtitle1: "Las mejores carnes maduradas que vas a probar",
      homeSubtitle2: "Un espacio perfecto en el que se fusionan la mejor gastronomía y el ocio",
    },
    en: {
      cartTitle: "Menu",
      bookTitle: "Book",
      homeTitle: "The best gastronomic and leisure experience is called Karnivoro",
      homeSubtitle1: "The best aged meats you'll ever taste",
      homeSubtitle2: "A perfect space where the best gastronomy and leisure come together",
    },
  };
  const navigate = useNavigate();

  const handleHrefPhone = () => {
    window.open("tel:+34722835293", "_blank");
  };
  const handlewhatsappPhone = () => {
    window.open("https://api.whatsapp.com/send?phone=34722835293", "_blank");
  };

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="bg-home">
      <div className="text-center">
        {showMenu && <MenuDropdown />}
      </div>
      <header className="banner">
        {/* <Image src={slider1} fluid height="70em" width="100%" /> */}
        <video
          className="video-banner"
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          width={"100%"}
        >
          <source src={video} type="video/mp4" />
        </video>
        <div className="button-container">
          <Button
            variant="primary"
            className="mt-2 text-uppercase text-light btn-cart"
            onClick={() => navigate("/carta")}
          >
            {languageData[lang].cartTitle}
          </Button>
        <Button
          variant="primary"
          className="mt-2 text-uppercase text-light btn-cart-outline"
          onClick={() => handleHrefPhone()}
        >
          {languageData[lang].bookTitle}
        </Button>
      </div>
        
      </header>
      {/* <img
        className="decor-image"
        src={
          "https://selvaticarestaurant.com/wp-content/uploads/2021/11/hoja1.svg"
        }
        alt="hoja"
      />
      <img
        className="decor-image2"
        src={
          "https://selvaticarestaurant.com/wp-content/uploads/2021/11/hoja2.svg"
        }
        alt="hoja"
      /> */}
      <img
        className="whatsapp"
        src={
          whatsapp
        }
        alt="whatsapp"
        onClick={() => handlewhatsappPhone()}
      />
      <div className="bg-home">
        <div className="bg-home">
          <img
            src={kwhite}
            alt="logo karnivoro"
            width="150"
          />
          <h2 className="text-center title-home text-uppercase mt-2 font-weight-700">
            {languageData[lang].homeTitle}
          </h2>

        </div>
        <Row className="no-gutters custom-row d-flex">
          <Col md={3} style={{ padding: "0", margin: "0" }}>
            <Image src={img4} alt="Image 1" style={{height: 600, objectFit: 'cover', width: '100%'}} fluid />
          </Col>
          <Col md={3} style={{ padding: "0", margin: "0" }}>
            <Image src={img5} alt="Image 2"  style={{height: 600, objectFit: 'cover'}} fluid />
          </Col>
          <Col md={3} style={{ padding: "0", margin: "0" }}>
            <Image src={img6} alt="Image 3"  style={{height: 600, objectFit: 'cover'}} fluid />
          </Col>
          <Col md={3} style={{ padding: "0", margin: "0" }}>
            <Image src={img7} alt="Image 4"  style={{height: 600, objectFit: 'cover'}} fluid />
          </Col>
        </Row>
        <div className="bg-home">
          <img
            src={kwhite}
            alt="logo karnivoro"
            width="150"
          />
          <h2 className="text-center title-home text-uppercase mt-2 font-weight-700">
            {languageData[lang].homeSubtitle1}
          </h2>
          <h3 className="text-center title-home text-uppercase mt-2">
            <i>Karnivoro</i>
          </h3>
        </div>
        <Row className="no-gutters custom-row d-flex">
          <Col md={6} style={{ padding: "0", margin: "0" }}>
            <Image src={img8} alt="Image 1" fluid style={{height: 1200, objectFit: 'cover'}} />
          </Col>
          <Col md={6} style={{ padding: "0", margin: "0" }}>
            <Image src={img9} alt="Image 2" fluid style={{height: 1200, objectFit: 'cover'}} />
          </Col>
          <Col md={12} style={{ padding: "0", margin: "0" }}>
            <Image src={img3} alt="Image 3" fluid width="100%" style={{height: 600, objectFit: 'cover'}} />
          </Col>
        </Row>
        <Row style={{ margin: "0 auto", padding: "4em"}}>
          <div className="bg-home">
            <img
              src={kwhite}
              alt="logo karnivoro"
              width="150"
            />
            <h2 className="text-center title-home text-uppercase mt-2 font-weight-700">
              {languageData[lang].homeSubtitle2}
            </h2>
            <h3 className="text-center title-home text-uppercase mt-2">
              <i>Karnivoro</i>
            </h3>
          </div>
        </Row>
        <Row className="no-gutters custom-row d-flex">
          <Col md={12} style={{ padding: "0", margin: "0" }}>
            <Image src={img11} alt="Image 3" fluid width="100%" style={{height: 600, objectFit: 'cover'}} />
          </Col>
          <Col md={6} style={{ padding: "0", margin: "0" }}>
            <Image src={img1} alt="Image 1" fluid style={{height: 1200, objectFit: 'cover'}} />
          </Col>
          <Col md={6} style={{ padding: "0", margin: "0" }}>
            <Image src={img12} alt="Image 2" fluid style={{height: 1200, objectFit: 'cover'}} />
          </Col>
          <Col md={12} style={{ padding: "0", margin: "0" }}>
            <Image src={img10} alt="Image 3" fluid width="100%" style={{height: 600, objectFit: 'cover'}} />
          </Col>
          {/* <Col md={12} style={{ padding: "0", margin: "0" }}>
            <Image src={img2} alt="Image 3" fluid width="100%" style={{height: 600, objectFit: 'cover'}} />
          </Col> */}
        </Row>
      </div>
    </div>
  );
};
