import "./App.scss";
import { Home } from "./views/Home";
import { Route, Routes } from "react-router-dom";
import { Principal } from "./views/Carta/Principal";
import { Footer } from "./components/footer/Footer";
import { Bebidas } from "./views/Carta/Bebidas";
import { Postres } from "./views/Carta/Postres";
import { Vinos } from "./views/Carta/Vinos";
import { Combinados } from "./views/Carta/Combinados";
import { NotFound } from "./views/NotFound/NotFound";
import { Carta } from "./views/Carta/Carta";
import { Login } from "./views/Auth/Login";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { LanguageProvider } from "./context/LanguageProvider";

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  useEffect(() => {
    setUser(Cookies.get("user"));
  }, [navigate]);

  return (
    <>
    <LanguageProvider>
      <div>
        {
          user ? (
            <div className="user">
              <p className="user__name">{user}</p>
              <p className="user__logout" onClick={() => {
                Cookies.remove("user");
                setUser("");
              }
              }>Logout</p>
            </div>
          ) : null
        }
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/carta" element={<Carta />} />
        <Route path="/principal" element={<Principal />} />
        <Route path="/bebidas" element={<Bebidas />} />
        <Route path="/postres" element={<Postres />} />
        <Route path="/vinos" element={<Vinos />} />
        <Route path="/combinados" element={<Combinados />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <div>
        <Footer />
      </div>
    </LanguageProvider>
    </>
  );
}

export default App;
