import React from 'react'
import { Button } from 'react-bootstrap'
import { MenuDropdown } from '../../components/menu/MenuDropdown'

export const NotFound = () => {
  return (
    <div>
        <div className='text-center'>  
            <MenuDropdown />   
        </div>
        {/* <h1>404 Error Page #2</h1> */}
        <p class="cart-title text-center mt-4">Creemos que te has perdido. </p>
        <div class="link-container">
            <Button variant="primary text-white" href="/">Volver al inicio</Button>
        </div>
    </div>
  )
}
