import React, { useEffect } from 'react'
import { Accordions } from '../../components/accordion/Accordions'
import { drinks_data } from '../../data/carta/drinks.js'
import { MenuDropdown } from '../../components/menu/MenuDropdown';
export const Bebidas = () => {

    const lang = localStorage.getItem('lang') || 'es';

    const data = {
        es: {
            title: 'Bebidas - Karnivoro Restaurant',
        },
        en: {
            title: 'Drinks - Karnivoro Restaurant',
        }
    }

    document.title = data.es.title;
    useEffect(() => {
        window.location.href = "https://app.okeymenu.com/lSmSoq/es";
      }, []);
  return (
    <div>
        <div className='text-center'>  
            <MenuDropdown />   
        </div>
        <div className='mt-4'> 
            <Accordions data={drinks_data} lang={lang} />
        </div>
    </div>
  )
}
